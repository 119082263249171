body {
  margin: 0;
  font-family: 'ARSMaquettePro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'KokomoBreeze';
  font-style: normal;
  font-weight: 400;
  src: url('./app/assets/fonts/Nicky-Laatz-KokomoBreeze-Regular.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: HandWritten;
  font-weight: 400;
  font-style: normal;
  src: url(./app/assets/fonts/Des-Montilles-Handwritten-Script/DesMontilles-Regular.otf) format("opentype"),
  url(./app/assets/fonts/Des-Montilles-Handwritten-Script/DesMontilles-Regular.woff) format("woff");
}

.box {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
