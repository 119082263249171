@font-face {
    font-family: 'ARSMaquettePro';
    font-style: normal;
    font-weight: bolder;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-Black.otf');
}
@font-face {
    font-family: 'ARSMaquettePro';
    font-style: italic;
    font-weight: bolder;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-BlackItalic.otf');
}
@font-face {
    font-family: 'ARSMaquettePro-Bold';
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-Bold.otf');
}
@font-face {
    font-family: 'ARSMaquettePro';
    font-style: italic;
    font-weight: bold;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-BoldItalic.otf');
}
@font-face {
    font-family: 'ARSMaquettePro-Light';
    font-style: normal;
    font-weight: lighter;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-Light.otf');
}
@font-face {
    font-family: 'ARSMaquettePro';
    font-style: italic;
    font-weight: lighter;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-LightItalic.otf');
}
@font-face {
    font-family: 'ARSMaquettePro';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-Medium.otf');
}
@font-face {
    font-family: 'ARSMaquettePro';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-MediumItalic.otf');
}
@font-face {
    font-family: 'ARSMaquettePro';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-Regular.otf');
}
@font-face {
    font-family: 'ARSMaquettePro';
    font-style: italic;
    font-weight: normal;
    src: url('./fonts/ARSMaquettePro/ARSMaquettePro-RegularItalic.otf');
}

/* For LG Banner, delete later */
@font-face {
    font-family: 'LGSmart-Bold';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/LGSmartFont/LG-Smart-Bold.otf');
}

@font-face {
    font-family: 'LGSmart';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/LGSmartFont/LG-Smart-Regular.otf');
}

@font-face {
    font-family: 'LGSmart-Light';
    font-style: normal;
    font-weight: 100;
    src: url('./fonts/LGSmartFont/LG-Smart-Light.otf');
}